<template>
  <div class="card">
    <div class="card-header"><strong>Posted by Cole Abat:</strong></div>
    <div class="card-body">
      <div class="author-image">
        <img
          src="/assets/images/author/cole.jpg"
          alt="Image"
          class="rounded-circle" />
      </div>
      <p>
        <i>
          <a href="https://www.linkedin.com/in/cole-abat/">Cole</a>
          is the Chief of Staff at eFlexervices. With over a decade of working
          in the BPO Industry, his keen eye for trends and passion for leading
          people have resulted in the successful management of various teams and
          projects over the years. He’s a proven leader in building
          high-performing teams, driving results, problem-solving, and focuses
          on long-term planning and projects to ensure the growth of the
          organization.
        </i>
      </p>
    </div>
  </div>
  <!-- Post Single - Author End -->
</template>

<script>
  export default {
    data() {
      return {}
    },
  }
</script>
